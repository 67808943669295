@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

body,
html {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: 'Poppins', sans-serif;
  font-size: 1rem;
}

h1 {
  @apply text-secondary text-2xl font-bold; /* Utiliza las clases de Tailwind para tamaño de fuente y color */
}

p {
  @apply text-secondary pb-5 text-lg font-semibold; /* Utiliza las clases de Tailwind para tamaño de fuente, peso y color */
}
